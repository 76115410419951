import React from "react";
import { PageProps } from "gatsby";
import { BlogList } from "../components/blog/BlogList";
import { LandingSmall } from "../components/Landing";
import { PageLayout } from "../components/PageLayout";
import { Helmet } from "react-helmet";

const BlogListPage = (props: PageProps) => {
  return (
    <PageLayout>
      <Helmet>
        <title>Aktuality</title>
      </Helmet>
      <LandingSmall name="aktuality" />
      <BlogList />
    </PageLayout>
  );
};

export default BlogListPage;
