import React from "react";
import { Page } from "../layout/Page";
import { BlogListView } from "./blogListView";

export const BlogList = () => {
  return (
    <Page>
      <BlogListView />
    </Page>
  );
};
